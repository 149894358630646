/*eslint no-unused-vars: "off"*/

import React, { useContext, useEffect, useState, useMemo } from 'react';
import { StateContext, DispatchContext } from "./Contexts";
import { useKey, triggerKey, UseLayer } from './Keyboard'
import { isKey } from './isKey'
import Breadcrumbs from './Breadcrumbs'
export default function () {
	const state = useContext(StateContext);
	const dispatch = useContext(DispatchContext);
	const { settings } = state
	let { actions } = state.json
	//console.log('list', actions);
	if (!actions) actions = [];
	let usedKeys = [];
	let usedGroups = [];

	const menuActions = useMemo(() => actions.map((origitem, i) => {
		let item = { ...origitem }
		let keycode = item.keycode && !item.groupkeycode ? item.keycode : null;
		let keys = keycode ? keycode.split(' ') : [keycode];
		//console.log(keys);
		if (keys.length > 1) keycode = settings.is_list && settings.is_list === "0" ? keys[1] : keys[0];
		//if (keycode === '') keycode = null;
		//console.log(origitem,keycode);
		if (keycode && usedKeys.includes(keycode.toLowerCase())) { keycode = null; }
		if (keycode) usedKeys.push(keycode.toLowerCase());
		item.keycode = keycode;
		//if(item.keycode) console.log(item.keycode,item.name);
		if (item.groupkeycode) {
			if (usedKeys.includes(item.groupkeycode.toLowerCase())) {
				if (item.groupkeycode !== item.keycode) item.groupkeycode = null;
			} else {
				if (item.groupname !== 'hidden') usedKeys.push(item.groupkeycode.toLowerCase());
			}
		}
		if (item.groupname && item.groupname !== 'hidden') {
			if (usedGroups.includes(item.groupname)) return null
			usedGroups.push(item.groupname);
			return <MenuGroup key={item.groupname + i + item.groupkeycode} params={item.params} item={item} isLoading={state.isLoading} ismobile={state.ismobile} disabled={state.hasPicklist} groupname={item.groupname} pathname={state.pathname} dispatch={dispatch} groupkeycode={item.groupkeycode} actions={actions} />
		}
		return <MenuAction key={state.pathname + item.name + i + item.action} hidden={item.groupname === 'hidden'} item={item} isLoading={state.isLoading} ismobile={state.ismobile} disabled={state.hasPicklist} pathname={state.pathname} dispatch={dispatch} />
	}), [actions]);

	//console.log(actions);
	return (
		<div className="wrapper-header m22-0 p22-0 pt22-1">
			<div className={"actionmenu row " + (state.edit !== null ? 'edit' : '')}>
				<div className="col-sm m-0 p-1">
					<Breadcrumbs />
				</div>
			</div>
			<div className={"actionmenu row " + (state.edit !== null ? 'edit' : '')}>
				<div className="col-sm m-0 p-0">
					{menuActions}
					{/* Object.keys(state.cardChanges).length ?
					<MenuAction item={{ name: 'cancel', disp: 'Cancel', action: 'cancel_card_changes', keycode: '', params: '{"className":"btn-danger"}' }} disabled={state.hasPicklist} pathname={state.pathname} dispatch={dispatch} />
					: ''
					*/}
				</div>
			</div>
			{settings.cardinfo ?
				<div className={"row"}>
					<div className="col-sm m-0 p-1" dangerouslySetInnerHTML={{ __html: settings.cardinfo }}>
					</div>
				</div> : ''}
		</div>
	);
}
function MenuGroup({ groupname, groupkeycode, params, ismobile, isLoading, actions, pathname, dispatch, disabled, item }) {
	const [showmenu, setShowmenu] = useState(false);
	const toggleMenu = () => {
		setShowmenu(!showmenu);
	}
	useEffect(() => setShowmenu(false), [actions]);
	useKey(groupkeycode, toggleMenu);
	let layer = "pickmodal";
	let paramss
	try {
		paramss = JSON.parse(params);
	} catch (e) { }
	if (!paramss) paramss = {}
	let className = "btn mx-1 py-1 dropdown-toggle"
	if (item.groupClassName) { className += " " + item.groupClassName }
	else if (paramss && paramss.className) { className += " " + paramss.className }
	else { className += " btn-info" };
	let usedKeys = [];
	//console.log(item)
	const rightClick = (evt) => {
		dispatch({ type: 'rename', context: 'menu', display: item.groupdisp, name: item.groupname });
		evt.preventDefault();
	}
	const buttons = showmenu ? (<div className="dropdown-menu22 show mx-1"><Escape layer={layer} toggleMenu={toggleMenu} />{
		actions.map((origitem) => {
			let item = { ...origitem }
			if (item.groupname !== groupname) return null;
			if (item.keycode && usedKeys.includes(item.keycode.toLowerCase())) item.keycode = null;
			if (item.keycode) usedKeys.push(item.keycode.toLowerCase());
			return <MenuAction inPickModal layer={layer} setShowmenu={setShowmenu} isLoading={isLoading} key={pathname + item.name + item.action} item={item} ismobile={ismobile} disabled={disabled} pathname={pathname} dispatch={dispatch} />
		})}</div>) : null;
	return (
		<>
			{showmenu ? <div className="pickmodal" onContextMenu={evt => triggerKey("Escape")} onMouseDown={evt => triggerKey("Escape")}>
				<UseLayer name={layer} />
				<div
					onMouseDown={e => e.stopPropagation()} onContextMenu={e => e.stopPropagation()}
				>{buttons}</div></div> : ''}
			<div className="btn-group">
				<button type="button"
					onContextMenu={rightClick}
					title={item.groupdisp}
					//onBlur={() => setShowmenu(false)}
					onClick={toggleMenu}
					className={className} >{item.groupdisp}</button>

			</div>
			{!ismobile && groupkeycode ? <div className="keycode">{groupkeycode}</div> : ''}
		</>
	);
}
function Escape({ toggleMenu, layer }) {
	useKey("escape", (evt) => {
		toggleMenu();
	}, [], layer);
	return null
}

function MenuAction({ item, dispatch, disabled, ismobile, setShowmenu, pathname, isLoading, hidden, inPickModal, layer }) {
	//const {addKey,remKey} = useContext(KeyContext);
	const [initialPath, setInitialPath] = useState(pathname)
	const [keycode, setKeyCode] = useState(item.keycode ?? '') //GB231110
	useEffect(() => {
		setInitialPath(pathname);
		if (item.keycode !== keycode) setKeyCode(item.keycode ?? ''); //GB231110
	}, [item]);

	//console.log(inPickModal);
	let itemkeycode = keycode ? keycode : false;
	//let itemkeycode = item.keycode ? item.keycode.toLowerCase() : false;
	//let itemkeycode = keycode ? keycode.toLowerCase() : false;
	let params
	try {
		params = JSON.parse(item.params);
	} catch (e) { }
	if (!params) params = {}
	params.initialPath = initialPath;
	if (!params.name) params.name = item.name;
	if (disabled) item.action = 'none';
	const action = () => {
		if (disabled || item.action === 'none') return
		//console.log('bla - MenuAction');
		if (params) {
			dispatch({ type: item.action, ...params });
		} else {
			dispatch({ type: item.action });
		}
	}
	const handleKey = evt => {
		//console.log('handleKey', evt, item)
		if (isLoading) return
		//		if (isKey(evt, itemkeycode)) {
		if (setShowmenu) setShowmenu(false);
		if (evt.target && evt.target.blur) evt.target.blur();
		action();
		//		}
	}
	const rightClick = (evt) => {
		dispatch({ type: 'rename', context: 'menu', display: item.disp, name: item.name });
		evt.preventDefault();
	}

	useKey(itemkeycode, handleKey, params.blacklistedTargets ? params.blacklistedTargets : ['INPUT', 'TEXTAREA'], layer);
	if (hidden) return null;
	let className = "btn m-1 py-1 ";
	className = className + (params && params.className ? params.className : "btn-info");
	className += inPickModal ? ' w-24' : '';
	let actionStateCount = item.stateCount !== null ? <>&nbsp;<span style={{ opacity: item.stateCount === 0 ? 0.25 : 1, fontSize: '0.6em', padding: '0.4em 0.6em', boxShadow: '0px 2px 5px rgba(0,0,0,0.4)' }} className="badge badge-pill badge-danger">{item.stateCount}</span></> : ''
	//console.log('erte',item)
	return item.action === 'none' || item.disabled ? <button onContextMenu={rightClick} readOnly={true} disabled={true} className={className}>{item.disp}{actionStateCount}</button>
		: <>
			<button onContextMenu={rightClick} title={item.disp} tabIndex="-1"
				onMouseDown={() => { dispatch({ type: 'pre_action', action: item.action }) }}
				onClick={action} className={className}>{item.disp}{actionStateCount}
			</button>
			{!ismobile && itemkeycode && item.keycode !== item.name ? <div className="keycode">{item.keycode}</div> : ''}
		</>
		;
}
