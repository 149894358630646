import React, { useContext } from 'react';
import { formatDate, formatFloat, formatDateTime, formatTime, formatAll } from '../format';
import { DispatchContext } from "../Contexts";
import { FiMinus, FiCheck } from "react-icons/fi";

export default function ({ fontsize, print, printonly, value, className, isLoading, setPostVal, name, data, def, extra, refInput, mask, maxLength, updateValue, valid, setValue, disabled, type, placeholder, onFocus }) {
	const dispatch = useContext(DispatchContext);
	let dataset;
	//console.log(def);
	try {
		dataset = JSON.parse(data);
	} catch (e) {
		dataset = extra;
	}
	let datadef;
	let lang;
	if (!dataset) return null
	if (dataset.def !== undefined) datadef = dataset.def;
	if (dataset.lang !== undefined) lang = dataset.lang;
	if (dataset.data !== undefined) dataset = dataset.data;
	let firstRecord = dataset && dataset.length ? dataset[0] : {}
	if ((dataset === null || dataset.length === 0) && datadef) {
		dataset = [];
		datadef.forEach(item => { firstRecord[item.name] = '' }); //GB 201116 BUG: show extra record when none there!
		//dataset.push(firstRecord)
	}
	if (!dataset) return null

	//console.log(datadef);

	const click = ({ evt, id, column, value }) => {
		if (isLoading) return
		if (column && name) {
			setPostVal({ name: name + "_column", value: column })
			setPostVal({ name: name + "_column_value", value: value })
		}
		setPostVal({ name, value: id, post: true, evt })
		//console.log(column);
		evt.preventDefault();
		evt.stopPropagation();
	}
	//console.log(def);
	//
	return <div className={"overflow-auto form-control pt-0" + (print ? ' print' : '') + (printonly ? ' printonly' : '')
		+ ' ' + (print ? (def && def.cls && def.cls.match(/h-(auto|[1-9]0)/gi) ? ' h-auto' : '') : (def && def.cls))}
		style={{ height: def && def.cls && def.cls.match(/h-(auto|[1-9]0)/gi) ? '100%' : '240px', fontSize: (fontsize ? fontsize : '100%') }}><Rows dispatch={dispatch} click={click} lang={lang} firstRecord={firstRecord} data={dataset} def={datadef} print={print} /></div>
}


function Rows({ data, def, dispatch, firstRecord, print, lang, click }) {
	//if (!Array.isArray(data) || data.length === 0) return null;
	let doRepeatheader = false;
	if (firstRecord) Object.keys(firstRecord).map((key) => { if (key.match(/[*][*]/gi)) doRepeatheader = true })
	//console.log(doRepeatheader, firstRecord)
	//console.log(lang);
	let last = { groupby: false, sum: {}, sumtotal: {}, /*avg: {}, avgtotal: {},*/ cnt: {}, cnttotal: {} };
	const rows = data.map((item, i) => <Row def={def} key={i} firstRecord={firstRecord} click={click} data={item} last={last} lastrow={(data.length - 1) === i} />);
	//let subtotalsheader = null
	//let totalsheader = null
	//console.log(last.sumtotal);
	//if (last.sum) subtotalsheader = getTotalsHeader({ data: data[0], sum: last.sum })
	//if (last.sumtotal) totalsheader = getTotalsHeader({ data: data[0], sum: last.sumtotal })

	// TODO: print los van "normaal"
	return <>
		{print ?
			<table style={{ width: '100%' }} >
				<thead><tr><td>{doRepeatheader ? null : <Row data={firstRecord} head click={click} def={def} lang={lang} dispatch={dispatch} />}</td></tr></thead>
				<tbody>
					<tr><td>
						{rows}
					</td></tr>
				</tbody>
			</table >
			:
			<>
				{doRepeatheader ? null : <Row data={firstRecord} head click={click} def={def} lang={lang} dispatch={dispatch} />}
				{rows}
			</>
		}
	</>
}

function Row({ data, head, last, group, total, totalsub, lastrow, def, dispatch, lang, firstRecord, click }) {
	//console.log(data);
	let groupby = '';
	let doRepeatheader = false;
	let sum = {};
	let cnt = {};
	const cols = Object.keys(data).map((key, i) => {
		let att = (def && def[i].att) ?? 'nvarchar(max)'
		let elem = (def && def[i].elem) ?? null;
		//		console.log(def);
		const disp = (def && def[i].disp) ?? key
		let cls = ''
		if (disp.match(/[~]/)) cls = disp.replace(/^.*[~](.*?)$/i, '$1') // use nozzle to define classes
		let display = head ? disp : data[key];
		if (head) {
			display = display.replace(/[~].*?$/i, '')
			display = display.replace(/[@\-*].*?$/i, '')
			display = display.replace(/[:][0-9][^0-9a-z]*?$/i, '')
		}
		let align = '';
		let scale = false;
		if (att === 'float') scale = 2;
		let matchesdec = att.match(/^decimal\(([0-9]+),([0-9]+)/)
		if (matchesdec) {
			scale = matchesdec[2];
		}

		if (key === 'id' || disp.match(/\-$/i)) return null;
		if (disp.match(/[*]/gi)) {
			if (disp.match(/[*][*]/gi)) doRepeatheader = true;
			//if (att.match(/^date/gi)) display = formatDate(display);
			if (!head) groupby += (groupby ? ' - ' : '') + display //data[key];
			return null;
		}
		let matches = disp.match(/[:]([0-9])[^0-9a-z]?/i)
		if (matches) {
			scale = matches[1];
		}
		if (att === 'int' || scale !== false) align = ' text-right';
		if (!head) {
			if (scale !== false) {
				if (display === null) cls += ' opacity-25 '
				display = formatFloat(display, scale);
				if (display.match(/^[-]0[,.]00$/)) display = display.replace("-", "");
			}
			display = formatAll(display, att, elem);
			//if (att.match(/^datetime2/gi)) display = formatDateTime(display);
			//else if (att.match(/^time/gi)) formatTime(display);
			//else if (att.match(/^date/gi)) display = formatDate(display);
			//if (att.match(/^date/gi)) display = formatDate(display);
			if (disp.match(/[@]/gi)) {
				//console.log(disp,key,disp.match(/[@]/gi));
				if (last) {
					if (!last.sumtotal[key]) last.sumtotal[key] = 0
					//					last.sumtotal[key] += parseFloat(data[key]) ?? 0;
					last.sumtotal[key] += data[key];
					if (!sum[key]) sum[key] = 0
					//					sum[key] += parseFloat(data[key]) ?? 0;
					sum[key] += data[key];
					if (disp.match(/[@][a]/gi)) {
						if (!last.cnttotal[key]) last.cnttotal[key] = 0
						last.cnttotal[key] += 1;
						if (!cnt[key]) cnt[key] = 0
						cnt[key] += 1;
					}
				}
			} else {
				if (total) display = ''
			}
			if (disp.match(/[@][a]?[g]/gi) && total && !totalsub) display = ''
			if (disp.match(/[@][a]?[t]/gi) && total && totalsub) display = ''
		}
		const rightClick = (evt) => {
			if (head) {
				dispatch({ type: 'rename', context: 'related_items_' + def[i].card, display: def[i].disp, name: def[i].name, lang: lang });
				evt.preventDefault();
			}
		}
		let title = display ? display.toString() : 'False'
		if (att === 'bit' && !head) {
			display = <FiMinus size="1.25em" style={{ opacity: 0.25 }} />
			title = disp + ': NULL'
			if (data[key] === true) { display = <FiCheck size="1.25em" />; title = disp + ': True'; }
			if (data[key] === false) { display = '\u00A0'; title = disp + ': False'; }
		}

		return <div
			title={title}
			onClick={(evt) => { if (!head && data && data.id) click({ evt, id: data.id, column: def && def[i] && def[i].name, value: data[key] }) }}
			onContextMenu={rightClick}
			style={(head ? { borderRadius: '6px', border: '1px solid #fff' } : {})}
			className={"col-sm m-0 px-1 text-truncate" + align + (head ? ' no-hover alert2 alert-primary py-0' : '') + (group ? ' wrap p-1 no-hover font-weight-bold alert-danger mt-2 py2-0' : '') +
				(total ? ' no-hover border-top border-bottom mb-2 font-weight-bold alert-2secondary' : '') + (cls ? ' ' + cls : '')}
			key={key + i + (head ? 'head' : '')}>{display}</div>
	});
	let groupbyheader = null
	let repeatheader = null
	let subtotalsheader = null
	let totalsheader = null
	let lastsubtotalsheader = null
	if (last) {

		if (groupby !== '' && (last.groupby !== groupby)) {
			if (last.groupby !== false && last.groupby !== groupby) {
				subtotalsheader = getTotalsHeader({ data, sum: last.sum, cnt: last.cnt, def, totalsub: true })
				last.sum = {}
				last.cnt = {}
			}
			groupbyheader = <Row data={{ groupby }} group />
			if (doRepeatheader) repeatheader = <Row data={firstRecord} head def={def} lang={lang} dispatch={dispatch} />
		}
		last.groupby = groupby;
		Object.keys(sum).forEach(key => {
			if (!last.sum[key]) last.sum[key] = 0
			last.sum[key] += sum[key];
			if (cnt[key]) {
				if (!last.cnt[key]) last.cnt[key] = 0
				last.cnt[key] += cnt[key];
			}
		})
		if (lastrow && Object.keys(last.sumtotal).length) {
			//console.log('f',groupby,last.sum,last.sumtotal);
			lastsubtotalsheader = getTotalsHeader({ data, sum: last.sum, cnt: last.cnt, def, totalsub: true })
			if (groupby) totalsheader = getTotalsHeader({ data, sum: last.sumtotal, cnt: last.cnttotal, def })
		}
		// console.log(last.sum);
	}
	return <>{subtotalsheader}{groupbyheader}{repeatheader}<div
		//onClick={()=>{console.log(data)}}
		className={"row no-gutters" + (head ? ' sticky-top' : ' hover')}>{cols}</div>{lastsubtotalsheader}{totalsheader}</>;
}
function getTotalsHeader({ data, sum, cnt, def, totalsub }) {
	let totals = { ...data };
	Object.keys(totals).forEach(key => {
		if (!sum[key]) {
			totals[key] = ''
		} else {
			totals[key] = Math.round(sum[key] * 100 / (cnt && cnt[key] ? cnt[key] : 1)) / 100;
		}
	})
	//console.log({ def,totals });
	return <Row def={def} data={totals} total totalsub={totalsub} />
}