import { useEffect, useContext, useState } from "react";
import { StateContext, DispatchContext } from "./Contexts";
import { globals } from './globals';
import processQueryString from './processQueryString'

export default function useFetch() {
	const dispatch = useContext(DispatchContext);
	const state = useContext(StateContext);
	const [controller, setController] = useState(false);
	const { pathname, search, base, re_fetch, cardChangesCounter, cardChanges, settings } = state;
	useEffect(() => {
		if (controller) controller.abort();
		const con = new AbortController()
		const signal = con.signal
		setController(con);
		async function fetchUrl(base, pathname, search) {
			const fetchUrl = base + pathname + search;
			//	if (state.isLoading) {
			//		dispatch({ type: 're_fetch' });
			//		return;
			//	}
			var headers = {}
			if (globals.bearer) headers['Authorization'] = 'Bearer ' + globals.bearer;
			//if (state.selectedIds.length) headers['agfx-selected-ids'] = state.selectedIds.join();
			//console.log(globals.prev_pathname+globals.prev_search);
			//headers['agfx-bs'] = "geert,".repeat(2000)+'geert';
			headers['agfx-referer-pathname'] = globals.prev_pathname ?? pathname;
			let prevsearch = globals.prev_search ?? search;
			//console.log(prevsearch);
			prevsearch = globals.selectedId !== undefined && settings.is_list && settings.is_list === "1" ? processQueryString(prevsearch, { id: globals.selectedId }) : prevsearch;
			if (globals.prev_search_set !== undefined) prevsearch = globals.prev_search_set
			//console.log(prevsearch);
			headers['agfx-referer-search'] = prevsearch;
			globals.prev_pathname = pathname;
			//console.log(settings.is_list);
			globals.prev_search = search
			delete globals.prev_search_set
			headers['agfx-pathname'] = pathname;
			headers['agfx-search'] = search;
			headers['agfx-selected-id'] = globals.selectedId ?? null;
			headers['agfx-selected-fid'] = state.settings.updatedfield ?? null;
			headers['agfx-client-uuid'] = state.clientUuid ?? null;
			headers['agfx-client-version'] = state.clientVersion ?? null;
			headers['Content-Type'] = 'application/json';
			dispatch({ type: 'fetch' });
			//console.log('fetchUrl', fetchUrl);
			var options = {
				method: 'GET',
				//	signal: signal,
				//credentials: "include",
				mode: 'cors',
				cache: 'no-cache',
				headers,
			}
			if (Object.keys(cardChanges).length || globals.api_multipart_form) {
				options.method = 'POST';
				if (globals.api_multipart_form) {
					options.body = globals.api_multipart_form;
					delete headers['Content-Type'];// = 'multipart/form';
				} else if (cardChanges["api_action_post"]) {
					let api_action_post = { ...cardChanges["api_action_post"] }
					if (state.selectedIdsByPath[pathname]) api_action_post['agfx-selected-ids'] = state.selectedIdsByPath[pathname].join();
					options.body = JSON.stringify({ ...cardChanges, api_action_post });
				} else {
					options.body = JSON.stringify(cardChanges);
				}
			} else {
				options.signal = signal;
			}
			globals.lastFetchMethod = options.method;
			//console.log(fetchUrl,state.isLoading);
			const response = await fetch(fetchUrl, options).catch(function (err) {
				console.error(` Err: ${err}`);
			});
			//globals.isLoading = false;
			if (globals.api_multipart_form) globals.api_multipart_form = false;
			//console.log(response);
			setController(false);
			if (response) {
				const json = await process(response);
				//console.log(json);
				if (json) dispatch({ type: 'json', json, fetchcounter: state.fetchcounter + 1 });
			} else {
				//alert('oooops33');
				dispatch({ type: 'json', json: {}, fetchcounter: state.fetchcounter + 1 });
				dispatch({ type: 'error', message: "Timeout or busy with maintenance, try again later / Timeout of bezig met onderhoud, probeer het later" });
			}
		}
		//console.log('useEffect - cardChanges', cardChanges);
		fetchUrl(base, pathname, search);
		//201014
	}, [cardChangesCounter, pathname, search, dispatch, /*bearer,*/ base, re_fetch]);// eslint-disable-line react-hooks/exhaustive-deps

	async function process(response) {
		const json = await response.json();
		if (json.table) console.table(json.table);
		if (json.timetable) console.table(json.timetable);
		if (json.data_error) console.table(json.data_error);
		//if (json.error || json.data_error) {
		if (json.error) {
			err(json)
			if (!json.views) return false;
		}
		return json;
	}
	function err({ error, data_error }) {
		var errormessage = error;
		if (data_error && data_error[0] && data_error[0].message) errormessage = data_error.map(e => e.message).join("\n");
		//if(data_error && data_error[0] && data_error[0].message) errormessage = data_error[0].message;
		if (Array.isArray(errormessage) && errormessage.length) errormessage = errormessage[0];
		if (typeof errormessage === 'string') errormessage = { message: errormessage };
		console.log(errormessage);
		var message = errormessage.message + (errormessage.sql ? "\n\n" + errormessage.sql : '');
		dispatch({ type: 'error', message });
	}

}

