import React, { useRef, useEffect } from 'react';
export default function ({ data, isLoading, setPostVal }) {
	const ref = useRef(null);
	let { html, name, iframe, className, iframe_message_data } = data;
	useEffect(() => {
		const mes = (event) => {
			let { data } = event;
			let { get_iframe_message_data } = data;
			if (get_iframe_message_data) {
				ref.current.querySelectorAll('iframe')[0].contentWindow.postMessage(iframe_message_data);
			} else {
				setPostVal({ name: name, value: data, post: true });
				event.preventDefault();
				event.stopPropagation();
			}
		}
		if (iframe) window.addEventListener('message', mes, false);

		return () => {
			if (iframe) window.removeEventListener('message', mes, false);
		}

	}, [setPostVal]);
	return <div ref={ref} /*disabled={isLoading} */ className={className} dangerouslySetInnerHTML={{ __html: html }} />
}



