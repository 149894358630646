import { useState, useEffect, useCallback } from 'react';
export function useTimer(initval, initfunc, millis = 1000) {
	const didMount = useDidMount();
	const [val, setVal] = useState(initval);
	const initfunc_cb = useCallback(initfunc, [])
	// const didMount = useCallback(useDidMount,[])
	useEffect(() => {
		if (didMount) {
			let timer = setTimeout(() => {
				//console.log('setTimeout');
				initfunc_cb(val);
			}, millis);
			return () => {
				//console.log('clearTimeout');
				clearTimeout(timer)
			}
		}
	}, [val, millis, initfunc_cb]);// eslint-disable-line react-hooks/exhaustive-deps
	//}, [val,didMount,millis,initfunc]);
	return [val, setVal]
}
export function useDidMount() {
	const [didMount, setDidMount] = useState(false)
	useEffect(() => setDidMount(true), [])
	return didMount;
}
export function escapeRegExp(string) {
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function useStateDelay(initval, millis = 1000) {
	const [val, setVal] = useState(initval);
	const [delayVal, setDelayVal] = useState(initval);
	let timer = false
	useEffect(() => {
		//if (delayVal === val) return;
		if (timer) clearTimeout(timer);
		//console.log('setTimeout')
		timer = setTimeout(() => {
			setVal(delayVal);
		}, millis);
		return () => {
			//console.log('clearTimeout')
			if (timer) clearTimeout(timer);
		}
	}, [delayVal]);
	return [val, setDelayVal]
}
export function getCoords(elem) {
	let box = elem.getBoundingClientRect();
	return {
		top: box.top + window.pageYOffset,
		bottom: box.bottom + window.pageYOffset,
		left: box.left + window.pageXOffset,
		right: box.right + window.pageXOffset,
	};
}
