
import React, { useEffect, useState, useContext } from 'react';
import { KeyContext } from "./Contexts";
import { globals } from './globals';
globals.keyTimeouts = 0
globals.keyTimeout = false;
globals.keyEvents = [];
export const Keyboard = ({ children }) => {
	const [keys, setKeys] = useState({});
	const [layers, setLayers] = useState(['top']);
	useEffect(() => {
		//console.log('Loading: ', globals.isLoading)
		const blacklistedTargets = ["INPUT", "TEXTAREA", "DIV"];
		const checkBuffer = () => {
			if (globals.keyTimeout) clearTimeout(globals.keyTimeout);
			if (!globals.isLoading) {
				let keyEvent = globals.keyEvents.shift();
				if (keyEvent.key.length === 1) {
					setTimeout(
						() => {
							if (document.activeElement.tagName !== 'BODY') {
								document.execCommand('insertText', false, keyEvent.key)
								//document.activeElement.dispatchEvent(new KeyboardEvent('keydown', keyEvent));
							}
						}
						, 5); //GB230222 GB230308 van 2 naar 5
				}
				document.activeElement.dispatchEvent(new KeyboardEvent('keydown', keyEvent));
				if (globals.keyEvents.length === 0) return
			}
			globals.keyTimeout = setTimeout(checkBuffer, 15);
		}
		const keydownListener = (evt) => {
			if ((evt.target.ariaRoleDescription ?? '') === "editor") return
			//console.log('event', evt, evt.key, globals.isLoading);
			if (!evt.key) return
			let key = evt.key;
			let keyEvent = { key: evt.key, cancelable: true, bubbles: false, code: evt.code, keyCode: evt.keyCode, which: evt.which, ctrlKey: evt.ctrlKey, altKey: evt.altKey, shiftKey: evt.shiftKey }
			//if (globals.isLoading || (globals.keyEvents > 0 && evt.isTrusted)) {
			if (true || key !== "ArrowDown" && key !== "ArrowUp") {
				if (globals.isLoading || (globals.keyEvents.length > 0 && evt.isTrusted)) {
					globals.keyEvents.push(evt)
					//console.log('keys:', globals.keyEvents);
					//if (globals.keyTimeout) clearTimeout(globals.keyTimeout);
					//globals.keyTimeout = setTimeout(checkBuffer, 10) //GB230308 van 200 naar 10
					evt.preventDefault();
					evt.stopPropagation();
					checkBuffer();
					return false;
				}
			}
			if (evt.code.match(/^key[a-z]$/i)) key = evt.code.replace(/^key/i, '');
			if (evt.code.match(/^digit[a-z]$/i)) key = evt.code.replace(/^digit/i, '');
			//console.log(key);
			key = key.toLowerCase();
			let keyprefix = ''
			if (evt.ctrlKey && evt.key !== "Control") keyprefix += "ctrl+";
			if (evt.altKey && evt.key !== "Alt") keyprefix += "alt+";
			//	if (evt.shiftKey && evt.key !== "Shift") keyprefix += "shift+";
			//	if (false) {
			//if (evt.ctrlKey && evt.key !== "Control") key = "ctrl+" + key;
			//if (evt.altKey && evt.key !== "Alt") key = "alt+" + key;
			if (evt.shiftKey && evt.key !== "Shift" && (key.length > 1 || key === " " || keyprefix)) key = "shift+" + key;
			key = keyprefix + key;
			//console.log(key);
			//	}
			//console.log("[" + key + "]")
			if (evt.target && evt.target.getAttribute && evt.target.getAttribute("ignore_keys")) {
				let blacklistedKeys = evt.target.getAttribute("ignore_keys").toLowerCase().split(',')
				if (blacklistedKeys.includes(key)) {
					//console.log(blacklistedKeys)
					//evt.preventDefault();
					//evt.stopPropagation();
					//globals.keyDone = true;
					return false;
				}
			}

			if (key.match(/^[a-z0-9 \-+.,/:;'"=_]$/i) && !keys[key] && keys["*"]) key = "*";
			if (key.match(/^alt[+][a-z0-9 \-+.,/:;'"=_]/i) && !keys[key] && keys["alt+*"]) key = "alt+*";
			//console.log('evt.key', evt.key, key, keys);
			//console.log(evt.key,'pressed...');

			if (keys[key] && keys[key].length) {
				//console.log(evt.key,'found...');
				//console.log("waaah", key, keys[key].length)
				let layer = keys[key][keys[key].length - 1].layer;
				//console.log(layer);
				let bl = keys[key][keys[key].length - 1].blacklisted ?? blacklistedTargets;
				if (layer === layers[layers.length - 1] && (Array.isArray(bl) || bl === null) && !bl.includes(evt.target.tagName)) {
					//console.log(evt.key,'using...');
					keys[key][keys[key].length - 1].callback(evt);
					if (key === "*" || key === "alt+*" || key === "tab" || key === "shift+tab") {
						//globals.keyDone = true;
						return true;
					}
					//console.log('prevdef');
					evt.preventDefault();
					evt.stopPropagation();
					//globals.keyDone = true;
					return false;
				}
				//console.log(evt.key,'not using...',layer,layers);
			}
			/*
			 else {
				//console.log("ignored!", evt.key)
			}
			*/
			//console.log("reserved key", key, evt.target.tagName);
			var reserved = ["alt", " ", "f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8", "f9", "f10", "f11", "pagedown", "pageup", "arrowdown", "arrowup", "escape", "home", "end", "enter"];
			if (blacklistedTargets.includes(evt.target.tagName)) reserved = ["alt", "f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8", "f9", "f10", "f11", "pagedown", "pageup", "arrowdown", "arrowup"];
			if (reserved.includes(key)) {
				if (['SELECT', 'TEXTAREA', 'DIV'].includes(evt.target.tagName) && ["arrowdown", "arrowup", "home", "end", "enter", "pagedown", "pageup"].includes(key)) return
				//console.log("reserved key", key, evt.target.tagName, blacklistedTargets);
				evt.preventDefault();
			}
			//console.log('Normal: ', evt);

			//console.log("active", keys);
			//console.log("waaah", evt.key,keys.a)
		}
		//console.log("Keyboard start")
		window.addEventListener("keydown", keydownListener, true);
		window.focus();
		//globals.keyDone = true;
		return () => {
			//console.log("Keyboard end")
			window.removeEventListener("keydown", keydownListener, true);
		}
	}, [keys, layers]);
	const addKey = (key, callback, blacklisted, layer) => {
		if (!key) return
		key = key.toLowerCase();
		//if(key==='c'||key==='b') console.log('addKey',key,callback.toString())
		//console.log('addKey',key,layer)
		let newKeys = keys
		if (!newKeys[key]) newKeys[key] = [];
		newKeys[key].push({ callback, blacklisted, layer })
		setKeys(newKeys);
	}
	const remKey = (key) => {
		if (!key) return
		key = key.toLowerCase();
		//console.log('remKey',key)
		let newKeys = keys
		if (newKeys[key] && newKeys[key].length) newKeys[key].pop();
		if (newKeys[key] && newKeys[key].length === 0) delete newKeys[key]
		setKeys(newKeys);
	}
	//console.log(layers[layers.length-1]);
	//console.log('werqwerqwerqwerqwer');
	return (
		<KeyContext.Provider value={{ addKey, remKey, layers, setLayers }}>{children}</KeyContext.Provider>
	);
}
export const useLayer = (layer) => {
	const { layers, setLayers } = useContext(KeyContext);
	const didMount = useDidMount();
	useEffect(() => {
		if (didMount && layer) {
			layers.push(layer);
			//console.log('startLayer',layer)
			setLayers(layers);
			return () => {
				if (layers) {
					let back = layers.pop();
					//console.log('stopLayer', back);

					setLayers(layers);
				}
			}
		}
	}, [didMount]);// eslint-disable-line react-hooks/exhaustive-deps
}
export function UseLayer({ name }) {
	useLayer(name)
	return null;
}
export const useKey = (key, callback, blacklisted, layer) => {
	const didMount = useDidMount();
	let uselayer = layer ? layer : 'top'
	//const [skey,setSkey] = useState(key);
	//const [callbackString,setCallbackString] = useState(callback.toString());
	//if(key !== skey) setSkey(key)
	//if(callbackString !== callback.toString()) setCallbackString(callback.toString());
	//console.log('equal',callbackString === callback.toString())
	//if(key==='insert') console.log('')
	const { addKey, remKey } = useContext(KeyContext);
	//	if(forceRemove) remKey(key);
	useEffect(() => {
		if (didMount && key) {
			//if(key==='Insert') console.log('addKey',key,layer,callback);
			addKey(key, callback, blacklisted, uselayer);
			return () => {
				//if(key==='Insert') console.log('remKey',key,layer,callback);
				remKey(key);
			}
		}
	//gb231124 }, [key, callback, blacklisted]);// eslint-disable-line react-hooks/exhaustive-deps
	});// eslint-disable-line react-hooks/exhaustive-deps
}
export function useDidMount() {
	const [didMount, setDidMount] = useState(false)
	useEffect(() => setDidMount(true), [])
	return didMount;
}
export function triggerKey(keystroke) {
	//console.log('triggerKey:', keystroke)
	let opt = { 'key': keystroke }
	if (keystroke === 'tab') opt = { "code": "9" }
	setTimeout(() => { document.dispatchEvent(new KeyboardEvent('keydown', opt)); }, 20);
}