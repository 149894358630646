/*eslint no-unused-vars: "off"*/
import React, { useContext, useEffect, useState } from 'react';

import AccountSignup from './account/Signup';
import AccountLogin from './account/Login';
import AccountUser from './account/User';
import AccountReset1 from './account/ResetStep1'
import AccountReset2 from './account/ResetStep2'
import AccountDelete from './account/Delete';
import { globals } from './globals';
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import { UserProvider, UserContext } from './services/UserContext'
import { DispatchContext } from "./Contexts";
export default function () {
	return (
		<div className="wrapper">
			<Router>
				<UserProvider server={globals.authserver1}>
					<GetUser />
					<div className="content container">
						<Route exact path="/account/signup" component={AccountSignup} />
						<Route exact path="/account/login" component={AccountLogin} />
						<Route exact path="/account/auth" component={AccountLogin} />
						<Route exact path="/account/user" component={AccountUser} />
						<Route exact path="/account/reset" component={AccountReset1} />
						<Route exact path="/account/reset/hash" component={AccountReset2} />
						<Route path="/account/delete" component={AccountDelete} />
					</div>
				</UserProvider>
			</Router>
		</div>
	);
}

const GetUser = withRouter(function ({ history, location }) {
	const [loc, setLoc] = useState(location);
	const user = useContext(UserContext);
	const dispatch = useContext(DispatchContext);

	//console.log(user);
	useEffect(() => {
		if (user.loading) return;
		if (user.authenticated) {
			let expiresTime = getCookie("expires")
			let expires = getCookie("expires") - Math.round((new Date()).getTime() / 1000);

			//console.log('setTimeout', expires);
			//dispatch({ type: 'user_authenticated',user:{token: false}  })
			//201014
			//const timeout = ()=>{user.refresh().then((token)=>dispatch({ type: 'user_authenticated',user:{token: token}  })); };
			//dispatch({ type: 'user_authenticated', user, timeout,expires })
			dispatch({ type: 'user_authenticated', user, expires, expiresTime })
			if (location.pathname.match(/^\/account\//i)) history.replace(loc.pathname + loc.search);
			//return null;
		}
		if (!location.pathname.match(/^\/account\//i)) {
			history.replace('/account/login');
		}
	}, [user]);
	return null;

});

function getCookie(name) {
	var regex = new RegExp('(?:(?:^|.*;\\s*)oauth_' + name + '\\s*\\=\\s*([^;]*).*$)|^.*$');
	return document.cookie.replace(regex, "$1");
}