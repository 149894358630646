import React, { useContext, useEffect, useState } from 'react';
import { DispatchContext } from "./Contexts";
import { useKey, triggerKey } from './Keyboard'
import { globals } from './globals';


export function ListCursor({ data, settings, setEditId, editId }) {
	const dispatch = useContext(DispatchContext);
	let id = settings.id ? parseInt(settings.id) : null;

	//console.log('a',id);
	let idx = null;
	for (let i = 0; i < data.length; i++) {
		if (data[i].id === id) {
			idx = i;
			break;
		}
	}
	if (idx === null) id = null
	const [selectedId, setSelectedId] = useState(id);
	const [index, setIndex] = useState(0);
	const [lastindex, setLastindex] = useState(index);
	const [shiftKey, setShiftKey] = useState(false);
	const datalength = data.length;
	globals.setSelectedId = setSelectedId;
	//console.log('selid',globals.selectedId);
	globals.selectedId = selectedId;
	//setVal("selectedId", selectedId);
	//setVal("setSelectedId", setSelectedId);
	//console.log('selectedId', selectedId);
	//console.log('index', index);
	//console.log('id', id);
	useEffect(() => {
		//console.log('i1', index);
		if (data && data[index]) {
			if (selectedId !== data[index].id) {
				setSelectedId(data[index].id);
				setLastindex(index);
			} else {
				//		console.log('i2',index);
			}
		} else {
			//	console.log('i1',index);
		}
	}, [index]);
	let tm;
	useEffect(() => {

		if (globals.setHandlerId) globals.setHandlerId(selectedId);
		if (globals.setStatusId) globals.setStatusId(selectedId);
		if (editId > 0) setEditId(data[index].id);
		//console.log('aah!!!',selectedId);
		if (tm) clearTimeout(tm);
		tm = setTimeout(() => {
			//console.log('aah!!!');
			if (selectedId === null) {
				setIndex(0);
			} else if (data && data.length && data[index].id !== selectedId) {
				let idx = null;
				for (let i = 0; i < datalength; i++) {
					if (data[i].id === selectedId) {
						idx = i;
						break;
					}
				}
				if (idx !== null && index !== idx) {
					setIndex(idx);
					//console.log('aah!!!');
				}
				// else if (idx === null) {
				//		setIndex(0);
				//	}
			}
		}, 300); //GB231114 naar 400 --GB 201007 van 500 naar 300
	}, [selectedId])
	useEffect(() => {
		if (id) {
			setSelectedId(id);
			let index = 0;
			for (let i = 0; i < datalength; i++) {
				if (data[i].id === id) {
					index = i;
					break;
				}
			}
			setIndex(index);
		} else {
			if (data && datalength) {
				let li = lastindex
				if (li && datalength - 1 < li) li = datalength - 1;
				if (li && globals.lastFetchMethod === 'POST' && data && data.length && data[li]) {
					setIndex(li);
					setSelectedId(data[li].id);
					return;
				}

				if (index > 0 && index > (datalength - 1)) {
					//					if (index > 0 && index !== datalength - 1) { //GB231107
					setIndex((datalength - 1));
					//console.log('aah!!!',index,datalength);
				} else {
					if (data[index]) setSelectedId(data[index].id);
				}
			}
		}
	}, [id, data])
	//console.log(data, settings);
	useKey(settings.noIsam ? " " : "*", evt => {
		dispatch({ type: 'showisam', show: true });
	}, settings.edit);

	const pageup = (idx) => {
		if (datalength && data[0].id !== parseInt(settings.first_id)) {
			dispatch({ type: "querystring", querystring: { id: null, off: parseInt(settings.reloff === "0" ? settings.off : settings.reloff) - parseInt(settings.top) } });
			if (idx !== undefined) setIndex(idx);
		} else {
			setIndex(0);
		}
	}
	const pagedown = (idx) => {
		if (datalength && data[datalength - 1].id !== parseInt(settings.last_id)) {
			dispatch({ type: "querystring", querystring: { id: null, off: parseInt(settings.reloff === "0" ? settings.off : settings.reloff) + parseInt(settings.top) } });
			if (idx !== undefined) setIndex(idx);
		} else {
			setIndex(datalength - 1);
		}
	}
	useKey("shift", evt => {
		setShiftKey(true);
	}, []);
	useKey("arrowup", evt => {
		if (index > 0) {
			setIndex(index - 1);
		} else {
			if (!evt.repeat) pageup(settings.top - 1);
			//pageup(settings.top - 1);
			//setIndex(datalength - 1);
		}
	}, []);
	useKey("shift+arrowdown", evt => {
		if (index < datalength - 1) {
			if (shiftKey) {
				dispatch({ type: 'invert_ids', ids: [data[index].id] })
				setShiftKey(false);
			}
			dispatch({ type: 'invert_ids', ids: [data[index + 1].id] })
			setIndex(index + 1);
		}
	})
	useKey("shift+end", evt => {
		if (index < datalength - 1) {
			let ids = [];
			for (let i = index; i < datalength; i++) ids.push(data[i].id);
			dispatch({ type: 'invert_ids', ids })
			setIndex(datalength - 1);
		}
	})
	useKey("shift+arrowup", evt => {
		if (index > 0) {
			if (shiftKey) {
				dispatch({ type: 'invert_ids', ids: [data[index].id] })
				setShiftKey(false);
			}
			dispatch({ type: 'invert_ids', ids: [data[index - 1].id] })
			setIndex(index - 1);
		}
	})
	useKey("shift+home", evt => {
		if (index > 0) {
			let ids = [];
			for (let i = index; i >= 0; i--) ids.push(data[i].id);
			dispatch({ type: 'invert_ids', ids })
			setIndex(0);
		}
	})
	/*	useKey("ctrl+a", evt => {
			let ids = [];
			for (let i = 0; i < datalength; i++) ids.push(data[i].id);
			dispatch({ type: 'select_ids', ids })
		}) */
	useKey("Shift+ ", evt => {
		dispatch({ type: 'toggle_selected', id: data[index].id })
	})
	useKey("arrowright", evt => {
		setEditId(data[index].id);
	});
	useKey("tab", evt => {
		setEditId(data[index].id);
		evt.preventDefault();
	});

	useKey("arrowdown", evt => {
		if (index < datalength - 1) {
			setIndex(index + 1);
		} else {
			if (!evt.repeat) pagedown(0);
		}
	}, []);
	useKey("pageup", evt => {
		//console.log(datalength, parseInt(state.settings.top));
		if (false && index > 0) { //GB231107 page up/down "normaal gedrag"
			setIndex(0);
		} else {
			pageup();
		}
	});

	useKey("pagedown", evt => {
		if (false && index < datalength - 1) { //GB231107 page up/down "normaal gedrag"
			setIndex(datalength - 1);
		} else {
			pagedown();
		}
	});
	useKey("home", evt => {
		dispatch({ type: "querystring", querystring: { off: null, ise: null, id: settings.first_id } });
	});
	/*useKey("ctrl+home", evt => {
		dispatch({ type: "querystring", querystring: { ord:1,off: null, ise: null, id: settings.first_id } });
		//dispatch({ type: "querystring", querystring: { off: null, ise: null } });
	});
	*/
	useKey("end", evt => {
		dispatch({ type: "querystring", querystring: { off: null, ise: null, id: settings.last_id } });
	});
	/*
	useKey("ctrl+end", evt => {
		dispatch({ type: "querystring", querystring: { ord:1, off: null, ise: null, id: settings.last_id } });
		//dispatch({ type: "querystring", querystring: { off: parseInt(settings.cnt) - parseInt(settings.top), ise: null } });
	});
	const handleWheel = (e) => {
		if (e.deltaY > 0) {
			triggerKey('ArrowDown');
		} else {
			triggerKey('ArrowUp');

		}
		e.preventDefault();
	}
	useEffect(() => {
		console.log('addmwheel');
		document.getElementById("mainlist").addEventListener('wheel', handleWheel)
		return () => {
			console.log('remwheel');
			document.getElementById("mainlist").removeEventListener('wheel', handleWheel)
		}
	}, [])
	
	setTimeout(() => {
		let elem = document.getElementById("id" + selectedId);
		console.log(elem);
		if (elem) elem.scrollIntoView({ behavior: "auto", block: "nearest" })

	}, 1000);
	*/
	useEffect(() => {
		let elem = document.getElementById("id" + selectedId);
		if (elem) elem.scrollIntoView({ behavior: "auto", block: "nearest" })
	}, [selectedId])
	return (
		<>
			{ //selectedId: {selectedId} - id: {id} - index: {index}
			}
			<style>{
				//"#id" + selectedId + "{background-color: #007bff !important;color: #fff !important;};"
				"#id" + selectedId + "{background-color: #ff8000 !important;color: #fff !important;}\n" +
				"#id" + selectedId + ".bg-info{background-color: #ff8080 !important;}"
				//body {overflow-y:hidden;}"
			}</style>
		</>)
}