/*eslint no-unused-vars: "off"*/
import React, { useContext, useEffect, useState } from 'react';
import { StateContext, DispatchContext } from "./Contexts";
import Field from './Field'
import { isKey } from './isKey'
import { useKey } from './Keyboard'
import Tabs from './Tabs'
import { getCoords } from './functions'
import Files from './Files'

export default function ({ datakey }) {
	const state = useContext(StateContext);
	const dispatch = useContext(DispatchContext);
	//console.log('s',state.settings.updatedfield)
	const [page, setPage] = useState(0) //200622 changed from false to 0 for crud_update issue
	//	const didMount = useDidMount();
	//	const [updatedfield, setUpdatedfield] = useState(state.settings.updatedfield ? parseInt(state.settings.updatedfield) : false)
	const updatedfield = state.settings.updatedfield ? parseInt(state.settings.updatedfield) : false;
	const setUpdatedfield = (fid) => {
		//if(state.settings.updatedfield === null) state.settings.updatedfield = fid
		if(state.settings.updatedfield === undefined) return
		let cur = state.settings.updatedfield ?? null;
		//console.log(fid,cur,state.settings.updatedfield)
		if (cur === fid) return;
		dispatch({ type: 'set_setting', key: 'updatedfield', value: fid });
	}
	//console.log(updatedfield, page);
	//console.log(state.settings,'set',updatedfield);
	//	if (updatedfield === false && fidElem) dispatch({ type: 'set_setting', key: 'updatedfield', value: fidElem.getAttribute("fid") });
	//console.log(state.edit);

	setTimeout(() => {
		let fidElem = document.querySelector(".focus[fid]");
		if (fidElem) {
			if (updatedfield === false) dispatch({ type: 'set_setting', key: 'updatedfield', value: fidElem.getAttribute("fid") });
			document.querySelector(".focus[fid]").scrollIntoView({ behavior: "smooth", block: "nearest" })
		}
	}, 200);
	//if (fidElem) fidElem.scrollIntoView({ behavior: "smooth", block: "center" });

	//console.log(state.isLoading);

	const [editUpdatedfield, setEditUpdatedfield] = useState(false);
	const data = state.json[datakey][0] ?? [];
	let disabled = state.edit !== null // || state.hasPicklist;
	const [def, setDef] = useState([]);
	useEffect(() => {
		setDef(state.json[datakey.replace(/^data/, 'def')]);
	}, [state.json]);

	if (data.length === 0) return null
	const is_ask_on_insert = (data && data.id !== undefined && data.id === 0) ? true : false;
	//useKeyLayer(1);
	useKey("tab", (evt) => {
		if (evt.target.tagName === 'TEXTAREA') {
			let tar = evt.target
			let s = tar.selectionStart
			let e = tar.selectionEnd
			if (s === e) {
				document.execCommand('insertText', false, "\t");
			} else {
				let sel = tar.value.substring(s, e)
				sel = sel.replace(/(^|[\n\r])([^\n\r])/gi, "$1\t$2")//.replace(/[\t]+$/gi,"")
				document.execCommand('insertText', false, sel);
				tar.setSelectionRange(s, s + sel.length)
				//document.execCommand('indent',true,null);
			}
			evt.preventDefault();
			return true;
		}
		let visibleInputs = document.querySelectorAll("[tabindex='1']:not(:disabled):not([readonly])");
		let first = visibleInputs[0];
		let last = visibleInputs[visibleInputs.length - 1];
		//if(editUpdatedfield) setEditUpdatedfield(false);
		if (!evt.shiftKey && evt.target === last) {
			evt.preventDefault();
			first.focus();
		} else if (evt.shiftKey && evt.target === first) {
			evt.preventDefault();
			last.focus();
		}
	}, []);
	useKey("shift+tab", (evt) => {
		if (evt.target.tagName === 'TEXTAREA') {
			let tar = evt.target
			let s = tar.selectionStart
			let e = tar.selectionEnd
			let sel = tar.value.substring(s, e)
			sel = sel.replace(/(^|[\n\r])\t/gi, "$1")//.replace(/[\t]+$/gi,"")
			document.execCommand('insertText', false, sel);
			tar.setSelectionRange(s, s + sel.length)
			evt.preventDefault();
			return true;
		}
		let visibleInputs = document.querySelectorAll("[tabindex='1']:not(:disabled):not([readonly])");
		let first = visibleInputs[0];
		let last = visibleInputs[visibleInputs.length - 1];
		//if(editUpdatedfield) setEditUpdatedfield(false);
		if (evt.target === first) {
			evt.preventDefault();
			last.focus();
		}
	}, []);
	//	console.log('geert1',updatedfield);


	let pages = {}
	let pagesmods = {}
	let pagesvalids = {}
	let firstFocus = false;
	let fieldName = false;
	let fieldItem = {};
	const columns = def.map((item, i) => {
		//if (!pages.includes(item.page)) pages.push(item.page);
		//if(item.valid === false) console.log('invalid',item.name);
		if (!pages[item.page] && !item.is_id && !item.disabled) pages[item.page] = item.fid;
		if (!pagesmods[item.page] && item.is_mod) pagesmods[item.page] = item.is_mod
		if (!pagesvalids[item.page] && item.valid === false) pagesvalids[item.page] = false;
		let fieldFocus = false;
		if (updatedfield === item.fid) {
			if (item.page !== page) setPage(item.page);
			firstFocus = true;
			fieldFocus = true;
			fieldName = item.name;
			fieldItem = item
		}
		if (!firstFocus && !updatedfield && !item.is_id && !item.disabled && item.is_upd && (item.page === page || page === false)) {
			firstFocus = true;
			fieldFocus = true;
			fieldName = item.name;
			fieldItem = item
			if (item.page !== page) setPage(item.page);
			setUpdatedfield(item.fid);
		}
		//console.log('fieldName',fieldName);
		//if(state.settings.updatedfield && item.fid === parseInt(state.settings.updatedfield)) fieldFocus = true;
		if (item.page !== page) return null;
		return <Field key={item.fid} data={data[item.name]} def={item} disabled={disabled} setEditUpdatedfield={setEditUpdatedfield} setUpdatedfield={setUpdatedfield} state={state} focus={fieldFocus} edit={fieldFocus && editUpdatedfield} />
	});
	if (!firstFocus && updatedfield && def.length && (updatedfield !== -99 || !state.settings.allow_file_types)) setUpdatedfield(false);
	if (page !== 99 && updatedfield === -99 && state.settings.allow_file_types) setPage(99);
	pages = Object.keys(pages).map((key) => {
		return { page: parseInt(key), fid: pages[key], is_mod: pagesmods[key], is_valid: pagesvalids[key], name: "Page " + (parseInt(key) + 1) }
	});
	if (state.settings.allow_file_types) {
		pages.push({ page: 99, fid: -99, is_mod: false, is_valid: true, name: "Files" })
	}/* else {
		if (page === 99) setPage(0)
	}*/
	if (page !== 0 && pages.findIndex(x => x.page === page) === -1) setPage(0)
	//console.log(pageIndex)
	useKey("delete", () => {
		if (fieldName && fieldItem.is_upd !== false && !fieldItem.disabled) dispatch({ type: 'reset_custom_form_field', key: fieldName })
	});
	useKey("backspace", () => {
		if (fieldName && fieldItem.is_nul !== false && fieldItem.is_upd !== false && !fieldItem.disabled) dispatch({ type: 'save', key: fieldName, val: null });
	});
	//	console.log('edit', editUpdatedfield);
	useKey("enter", (evt) => {
		setEditUpdatedfield(!editUpdatedfield);
		if (evt.target.tagName === "INPUT" || evt.target.tagName === "SELECT") setEditUpdatedfield(false);
	}, ["TEXTAREA", "BUTTON"]);
	useKey("arrowup", (evt) => {
		let newFid = findElementId("up");
		if (newFid && newFid !== updatedfield) {
			if (editUpdatedfield) setEditUpdatedfield(false);
			document.activeElement.blur();
			setUpdatedfield(newFid);
		}
	}, ["TEXTAREA", "SELECT"]);
	useKey("arrowdown", (evt) => {
		let newFid = findElementId("down");
		if (newFid && newFid !== updatedfield) {
			if (editUpdatedfield) setEditUpdatedfield(false);
			document.activeElement.blur();
			setUpdatedfield(newFid);
		}
	}, ["TEXTAREA", "SELECT"]);
	useKey("arrowleft", (evt) => {
		let newFid = findElementId("left");
		if (newFid && newFid !== updatedfield) {
			setUpdatedfield(newFid);
		} else {
			//		let pageIndex = pages.findIndex(x => x.page === page);
			//		if (pageIndex > 0) {
			//			setUpdatedfield(pages[pageIndex - 1].fid);
			//		}
		}
	});
	useKey("arrowright", (evt) => {
		let newFid = findElementId("right");
		if (newFid && newFid !== updatedfield) {
			setUpdatedfield(newFid);
		} else {
			//			let pageIndex = pages.findIndex(x => x.page === page);
			//			if (pageIndex < pages.length - 1) {
			//				setUpdatedfield(pages[pageIndex + 1].fid);
			//			}
		}
	});
	useKey(!is_ask_on_insert && "PageUp", (evt) => {
		let pageIndex = pages.findIndex(x => x.page === page);
		if (pageIndex > 0) {
			setUpdatedfield(pages[pageIndex - 1].fid);
		}
	}, ["TEXTAREA", "SELECT"]);
	useKey(!is_ask_on_insert && "PageDown", (evt) => {
		let pageIndex = pages.findIndex(x => x.page === page);
		if (pageIndex < pages.length - 1) {
			setUpdatedfield(pages[pageIndex + 1].fid);
		}
	}, ["TEXTAREA", "SELECT"]);
	/*
	useKey(".", (evt) => {
		let idx = def.findIndex(x => x.fid === updatedfield && x.page === page);
		let disp = def[idx].disp ?? false;
		if (disp && disp.match(/ [a-z]{2}$/i)) {
			let [match, field, lang] = disp.match(/^(.*) ([a-z]{2})$/i)
			let regx = new RegExp("^" + field + "([^a-z0-9]|$)", "gi");
			let origidx = def.findIndex(x => x.disp !== match && x.disp.match(regx) && data[x.name]);
			let text = data[def[origidx].name]
			let origdisp = def[origidx].disp ?? false;
			if (origdisp && origdisp.match(/ [a-z]{2}$/i)) {
				let [origmatch, origlang] = origdisp.match(/ ([a-z]{2})$/i)
				lang = origlang + '-' + lang
			}
			let url = "https://translate.yandex.net/api/v1.5/tr.json/translate?key=" + state.settings.yankey + "&format=html&lang=" + lang + "&text=" + text;
			fetch(url).then(response => response.json()).then(json => {
				let translated = json.text[0] ?? false;
				if (translated) dispatch({ type: 'save', key: def[idx].name, val: translated });
				console.log(text, lang, translated, url);
			})
		}

	});
	*/
	//console.log('best',page);
	useKey("*", (evt) => {
		let idx = def.findIndex(x => x.fid === updatedfield && x.page === page);
		if(!def[idx]) return
		//console.log('*', evt, def[idx]);
		if (def[idx].pl_typ > 0 && evt.code !== 'Space') return
		/*
		let oldidx = def.findIndex(x => x.fid === updatedfield && x.page === page);
		let newidx = def.findIndex((x, idx) => !x.is_id && idx > oldidx && x.disp.charAt(0).toLowerCase() === evt.key.toLowerCase() && x.page === page);
		if (newidx === -1) newidx = def.findIndex(x => !x.is_id && x.disp.charAt(0).toLowerCase() === evt.key.toLowerCase() && x.page === page);
		if (newidx > -1) setUpdatedfield(def[newidx].fid);
		*/
		if (!editUpdatedfield) setEditUpdatedfield(!editUpdatedfield);
	});
	useKey("alt+*", (evt) => {
		evt.preventDefault();
		evt.stopPropagation();
		let oldidx = def.findIndex(x => x.fid === updatedfield);
		let newidx = def.findIndex((x, idx) => !x.is_id && idx > oldidx && x.disp.charAt(0).toLowerCase() === evt.key.toLowerCase());
		if (newidx === -1) newidx = def.findIndex(x => !x.is_id && x.disp.charAt(0).toLowerCase() === evt.key.toLowerCase());
		if (newidx > -1) setUpdatedfield(def[newidx].fid);
		//console.log('best',evt,newidx);
	});
	/*
const swapPage = (fid, page) => {
for (let i = 0; i < def.length; i++) {
if (def[i].fid === fid) {
def[i].page = page;
setDef(def);
break;
}
}
}
const swapFields = (fid1, fid2) => {
let first = false;
let tmpdef = def;
for (let i = 0; i < tmpdef.length; i++) {
let obj = tmpdef[i];
if (obj.fid === fid1 || obj.fid === fid2) {
//console.log(fid1,fid2);
if (first === false) {
	first = i;
} else {
	let fobj = { ...tmpdef[first] }
	tmpdef[first] = tmpdef[i]
	tmpdef[i] = fobj;
	//setDef(tmpdef);
	break;
}
}
}


}
*/
	const fieldReOrder = (fid, move) => {
		let from = def.findIndex(x => x.fid === fid);
		let to = from + move;
		if (to > -1 && to < def.length) {
			if (def[from].page !== def[to].page) def[from].page = def[to].page;
			def.splice(to, 0, def.splice(from, 1)[0]);
			setDef(def);
		}
	}

	/*
	useEffect(() => {
		//console.log('page',page,updatedfield)
		//setUpdatedfield(false);
	}, [page]);
	useEffect(() => {
		//console.log('updatedfield',page,updatedfield)
		//setUpdatedfield(false);
	}, [updatedfield]);
*/
	let fieldMoverProps = { def, setDef, updatedfield, setUpdatedfield, fieldReOrder };
	//console.log("editUpdatedfield",editUpdatedfield);
	return <>
		{!is_ask_on_insert && <Tabs pages={pages} page={page} dispatch={dispatch} pathname={state.pathname} />}
		{state.settings.is_admin ? <FieldMover {...fieldMoverProps} /> : ''}
		{editUpdatedfield ? <Escape setEditUpdatedfield={setEditUpdatedfield} dispatch={dispatch} /> : ''}
		<div className="wrapper-content pt-2">
			<div className={"row " + (state.edit !== null ? 'edit' : '')}>{
				page === 99
					? <Files />
					:
					columns
			}</div>
		</div>
	</>
}
function Escape({ setEditUpdatedfield, dispatch }) {
	useKey("Ctrl+S", (evt) => {
		//console.log("escape",evt.target.tagName);
		if (evt.target.name) {
			if (evt.target.tagName === "INPUT" || evt.target.tagName === "SELECT" || evt.target.tagName === "TEXTAREA") {
				//console.log("insert", evt.target.tagName, evt.target.name, evt.target.value);
				dispatch({ type: 'save', key: evt.target.name, val: evt.target.value, save: true });
			}
		}
	}, []);
	useKey("F5", (evt) => {
		//console.log("escape",evt.target.tagName);
		if (evt.target.name) {
			if (evt.target.tagName === "INPUT" || evt.target.tagName === "SELECT" || evt.target.tagName === "TEXTAREA") {
				//console.log("insert", evt.target.tagName, evt.target.name, evt.target.value);
				dispatch({ type: 'save', key: evt.target.name, val: evt.target.value, save: true });
			}
		}
	}, []);



	useKey("escape", (evt) => {
		//console.log("escape", evt.target.tagName);
		if (evt.target.tagName === "INPUT" || evt.target.tagName === "SELECT" || evt.target.tagName === "TEXTAREA") setEditUpdatedfield(true);
		setEditUpdatedfield(false);
	}, []);
	return null
}
function FieldMover({ def, setDef, updatedfield, setUpdatedfield, fieldReOrder }) {
	const moveToPage = (pagenum) => {
		let idx = def.findIndex(x => x.fid === updatedfield);
		if (idx === -1 || def[idx].page === pagenum) return
		//let nextFid = pagenum > def[idx].page && (idx + 1) < def.length ? def[idx + 1].fid : def[idx].fid;
		let nextFid = false;
		let idxDelta = pagenum > def[idx].page ? 1 : 1;
		if (def[idx + idxDelta] && def[idx].page === def[idx + idxDelta].page) nextFid = def[idx + idxDelta].fid;
		if (nextFid === false && def[idx - idxDelta] && def[idx].page === def[idx - idxDelta].page) nextFid = def[idx - idxDelta].fid;
		//console.log(idxDelta,nextFid);
		//if(!nextFid && def[idx-1]) nextFid = def[idx-1].fid;
		def[idx].page = pagenum;
		let moveidx = def.findIndex(x => x.page === pagenum + 1);
		if (moveidx > 0) {
			def.splice(moveidx, 0, def.splice(idx, 1)[0]);
		} else {
			def.splice(def.length - 1, 0, def.splice(idx, 1)[0]);
		}
		setDef(def);
		//console.log(def);
		setUpdatedfield(nextFid ? nextFid : updatedfield);
	}
	useKey("ctrl+1", () => moveToPage(0));
	useKey("ctrl+2", () => moveToPage(1));
	useKey("ctrl+3", () => moveToPage(2));
	useKey("ctrl+4", () => moveToPage(3));
	useKey("ctrl+5", () => moveToPage(4));
	useKey("ctrl+6", () => moveToPage(5));
	useKey("ctrl+7", () => moveToPage(6));
	useKey("ctrl+8", () => moveToPage(7));
	useKey("ctrl+9", () => moveToPage(8));
	useKey("ctrl+0", () => moveToPage(9));
	useKey("shift+arrowleft", () => {
		fieldReOrder(updatedfield, -1);
		setUpdatedfield(updatedfield);
	});
	useKey("shift+arrowright", () => {
		fieldReOrder(updatedfield, 1);
		setUpdatedfield(updatedfield);
	});
	/*
	useKey("shift+arrowleft2", (evt) => {
		let newFid = findElementId("left");
		if (newFid && newFid !== updatedfield) {
			swapFields(updatedfield, newFid);
			setUpdatedfield(updatedfield);
		} else {
			if (page > 0) {
				swapPage(updatedfield, page - 1);
				swapFields(updatedfield, pages[page - 1].fid);
				setUpdatedfield(updatedfield);
			}
		}
	});
	useKey("shift+arrowright2", (evt) => {
		let newFid = findElementId("right");
		if (newFid && newFid !== updatedfield) {
			swapFields(updatedfield, newFid);
			setUpdatedfield(updatedfield);
		} else {
			if ((page + 1) < pages.length) {
				swapPage(updatedfield, page + 1);
				swapFields(updatedfield, pages[page + 1].fid);
				setUpdatedfield(updatedfield);
			}
		}
	});
	
	useKey("shift+arrowdown", (evt) => {
		let newFid = findElementId("down");
		if (newFid && newFid !== updatedfield) {
			swapFields(updatedfield, newFid);
			setUpdatedfield(updatedfield);
		}
	});
	useKey("shift+arrowup", (evt) => {
		let newFid = findElementId("up");
		if (newFid && newFid !== updatedfield) {
			swapFields(updatedfield, newFid);
			setUpdatedfield(updatedfield);
		}
	});
*/
	return null;
}
function findElementId2(move) {
	let fids = document.querySelectorAll("[fid][arrow-skip=false]");
	let fid = document.querySelector(".focus[fid]")
	if (!fid || !fids) return
	let fidCoords = getCoords(fid);
	let newfid = fid;
	for (let i = 0; i < fids.length; i++) {
		let pfid = fids[i];
		let coords = getCoords(pfid);
		switch (move) {
			case 'up': {
				if (coords.top < fidCoords.top && coords.left === fidCoords.left) newfid = pfid;
				break;
			}
			case 'down': {
				if (newfid === fid && coords.top > fidCoords.top && coords.left === fidCoords.left) newfid = pfid;
				break;
			}
			case 'left': {
				if (fidCoords.left >= coords.right - 2 && fidCoords.top >= coords.top - 2) newfid = pfid;
				break;
			}
			case 'right': {
				if (fidCoords.right <= coords.left + 2 && fidCoords.top >= coords.top - 2) newfid = pfid;
				break;
			}
		}
	}
	let id = newfid.getAttribute("fid");
	if (id) return parseInt(id);
}
let lastElPos = ''
function findElementId(move) {
	let found = 0;
	let elemArr = []

	document.querySelectorAll("[fid][arrow-skip=false]").forEach(elem => { elemArr.push(elem) });
	//if (move === 'left' && evt.target.selectionStart !== 0) return
	//if (move === 'right' && evt.target.selectionEnd !== evt.target.value.length) return
	if (move === 'up' || move === 'left') elemArr.reverse();

	let fid = document.querySelector(".focus[fid]")
	if (!fid || !elemArr) return
	//let fidCoords = getCoords(fid);
	let newfid = fid;

	let targetCoords = getCoords(fid);
	elemArr.forEach(elem => {
		if (found > 0) return
		let coords = getCoords(elem);
		switch (move) {
			case 'up': {
				if (coords.top < targetCoords.top && coords.left === targetCoords.left && lastElPos !== 'right') { found = 2; lastElPos = 'left' }
				if (coords.top < targetCoords.top && coords.right === targetCoords.right && lastElPos !== 'left') { found = 2; lastElPos = 'right' }
				break;
			}
			case 'down': { //lastElPos
				if (coords.top > targetCoords.top && coords.left === targetCoords.left && lastElPos !== 'right') { found = 2; lastElPos = 'left' }
				if (coords.top > targetCoords.top && coords.right === targetCoords.right && lastElPos !== 'left') { found = 2; lastElPos = 'right' }
				break;
			}
			case 'left': {
				if (coords.top === targetCoords.top && coords.left < targetCoords.left) { found = 2; lastElPos = 'left' }
				break;
			}
			case 'right': {
				if (coords.top === targetCoords.top && coords.left > targetCoords.left) { found = 2; lastElPos = 'right' }
				break;
			}
		}
		if (found === 2) {
			newfid = elem;
		}
		//lastfindElementPos = move;
	});
	let id = newfid.getAttribute("fid");
	if (id) return parseInt(id);

}

/*
function Fieldset({ def, data }) {
	return <div className="col-sm-12 alert alert-info m-0 mt-2">
		<div className="row">
			<div className={"col-sm-2 p-1 lead"}>
				{def.disp}
			</div>
			<div className="col-sm-10 p-2">
				<small>{data}</small>
			</div>
		</div>
	</div>
}
*/