import { format } from 'date-fns'
//import { en } from 'date-fns/locale'
let formatDateString = "dd-MM-yyyy";
let formatDateTimeString = "dd-MM-yyyy HH:mm";
let formatTimeString = "HH:mm";
let currentDatpicker = false;

export function parseDate(dt) {
	let parsed = Date.parse(dt);
	if (!parsed) return null;
	return new Date(parsed);
}

export function formatDate(dt) {
	let date = parseDate(dt);
	if (date === null) return "";
	return format(date, formatDateString)
}
export function formatDateTime(dt) {
	let date = parseDate(dt);
	if (date === null) return "";
	return format(date, formatDateTimeString)
}
export function formatTime(dt) {
	let date = parseDate(dt);
	if (date === null) return "";
	return format(date, formatTimeString)
}
export function formatAll(display, att, elem) {
	if (!display) return display;
	//console.log(display, att, elem)
	if (elem && elem === 'Time') {
		if (att.match(/^datetime/gi)) display = formatTime(display);
		else display = display.substring(0, 5)
	}
	else if (elem && elem === 'Datetime') display = formatDateTime(display);
	else if (elem && elem === 'Date') display = formatDate(display);
	else if (att === 'date') display = formatDate(display);
	else if (att === "smalldatetime" || att.match(/^datetimeoffset/gi) || att.match(/^datetime2/gi)) display = formatDateTime(display);
	else if (att.match(/^datetime/gi)) display = formatDate(display);
	else if (att.match(/^time/gi)) display = display.substring(0, 8);
	//console.log(att,elem);
	return display
}


export function formatFloat(num, scale = 2) {
	let newnum = num ?? 0;
	//console.log(num);
	return new Intl.NumberFormat('nl-NL', { minimumFractionDigits: scale, maximumFractionDigits: scale }).format(newnum) //+ ' float';
	//return newnum.toFixed(scale).replace();
}

export function toLocalISOString(dt) {
	if (dt === null) return
	let str = '';
	try {
		if (!(dt instanceof Date)) {
			dt = new Date(dt);
		}
		let z = dt.getTimezoneOffset() * 60 * 1000
		str = (new Date(dt - z)).toISOString().split(".")[0];

	} catch (e) {
		str = '';
	}
	return str;
}

export function setCurrentDatpicker(ref) {
	if (currentDatpicker !== ref && currentDatpicker && currentDatpicker.current && currentDatpicker.current.setOpen) {
		currentDatpicker.current.setOpen(false);
	}
	currentDatpicker = ref;
}

export function setFormatDateString(str) {
	formatDateString = str;
}
export function setFormatDateTimeString(str) {
	formatDateTimeString = str;
}
export function setFormatTimeString(str) {
	formatTimeString = str;
}

export function setDateFormat(str) {
	formatDateString = str;
}
export function getDateFormat(str) {
	return formatDateString;
}