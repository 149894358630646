import React, { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { useStateDelay } from '../functions'
import { FaTimesCircle } from "react-icons/fa";
import { globals } from '../globals';
import { DispatchContext } from "../Contexts";
import { UseLayer } from '../Keyboard';
export default function ({ data, setPostVal, ismobile, isLoading, onArrowKeys, setLastFocus, lastFocus }) {
	//console.log(data, isLoading);
	//return ''
	const dispatch = useContext(DispatchContext);

	let ajaxDelay = data.ajaxDelay ?? 600;
	//ajaxDelay = 5000;
	const ref = useRef(null);
	const [search, setSearch] = useStateDelay(data.search, ajaxDelay);
	const [updated, setUpdated] = useState(false);

	const [value, setValue] = useState(data.search);
	const [focus, hasFocus] = useState(false);
	const [previousFocus, setPreviousFocus] = useState(lastFocus);
	//const [choosing, setChoosing] = useState(false);
	const [index, setIndex] = useState();
    //const isNotLoading = (value === data.search && !isLoading && focus)

/*    useEffect(() => {
        setChoosing(false)
    },[data])
*/    useEffect(() => {
		if (focus) {
			//console.log('eff1')
			setIndex(0);
		}
	}, [focus]);
	useEffect(() => {
		let elem = document.getElementById("listitem_a_active_" + index)
		if (elem) elem.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
	}, [index]);
	/*
		useEffect(() => {
			setSearch(value);
		}, [value]);
		*/
	useEffect(() => {
		if (search === value && search !== data.search) {
			if (updated) {
				//console.log('updated!')
				setPostVal({ name: data.name, value: { search: search }, post: true })
			}
		}
		setSearch(value);
	}, [search, data.search, value]);
	const datalist = data.datalist;
	const choose = (idx) => {
		//console.log('end', value,data, isLoading, focus)
		if (idx === null) if ((data.search !== "" && value !== data.search) || isLoading || !focus) return; //GB201229 added: data.search!=="" &&
		let item = datalist[idx ? idx : index];
		if (!item) return
		if (idx && idx !== index) setIndex(idx)
		setUpdated(false);
		setValue(item.name);
		let modalroute = item.modalroute ?? data.modalRoute
		if (modalroute) {
			globals.selectedId = item.id

			let pathname = modalroute.split("?", 2)
			let search = pathname[1] ?? "";
			pathname = pathname[0];
			dispatch({ type: 'hide_modal' });
			dispatch({ type: 'pathname', pathname, search });
		} else {
			//hasFocus(false);
			//setLastFocus(data.name + '_done');
			//setLastFocus(null);
			setPostVal({ name: data.name, value: { search: item.name, id: item.id }, post: true })
		}

	}
	//let datalist = [{ id: 1, name: 'Geert' }, { id: 2, name: 'Geer2' }, { id: 3, name: 'Geert3' }, { id: 4, name: 'Geert4' }, { id: 5, name: 'Geer5' }, { id: 6, name: 'Geert6' }]
	const onKeyDown = (evt) => {
		if (evt.key === 'ArrowDown') if (index < datalist.length - 1) { setIndex(index + 1) } else {
			/*setUpdated(false);
			setValue(datalist[index].name);
			setPostVal({ name: data.name, value: { search: datalist[index].name }, post: true })
			setIndex(0)
			*/
		};
		if (evt.key === 'ArrowUp') if (index > -1) {
			setIndex(index - 1);
		} else {
			onArrowKeys(evt)
		}
		//console.log('treu',updated);
		if (evt.key === 'Enter' && index > -1 && !isLoading && !updated) { evt.target.blur(); choose(); }
		if (evt.key === 'Enter') {
			//gb230924 kijken if met Enter sneller werkt:
			//if(search) setPostVal({ name: data.name, value: { search: search }, post: true })
			evt.preventDefault(); evt.stopPropagation();
			return false;

		}
		setUpdated(false);
	}
	/*
	useEffect(() => {
		if (!isLoading && ref.current && ref.current.select && data.select) {
			// console.log('eff5')
			ref.current.select();
		}
	}, [isLoading, data]) //201014
	*/
	useEffect(() => {
		//console.log(data.focus)
		//if (!isLoading && ref.current && ref.current.focus && data.focus !== null) {
		//console.log('eff6')
		//	if (((newFocus===null && data.focus) || newFocus===data.name) && !focus) ref.current.focus();
		//if (!data.focus && focus) ref.current.blur();
		//}
		//console.log(previousFocus);
		if (!isLoading && ref.current && ref.current.focus && lastFocus === data.name) {
			ref.current.focus();
			setUpdated(false);
			if (previousFocus !== data.name && ref.current.select && data.select) ref.current.select();
		}
		setPreviousFocus(lastFocus);
	}, [isLoading, data]) //201019
	//let dataBefore = []
	//console.log(value, search, isLoading, focus);
	const listitems = useMemo(() => {
		if (datalist.length <= index && (datalist.length - 1) !== index) setIndex(datalist.length - 1);
		return datalist.map((item, idx) => {
			return <a key={idx} className={"py-" + (ismobile ? '3' : '1') + " px-2 list-group-item pointer list-group-item-action" + (idx === index ? " active" : "")}
				id={"listitem_a_active_" + idx}
				//onMouseOver={() => { setIndex(idx) }}
				onMouseDown={() => { choose(idx) }}
			//onMouseUp={(evt) => {evt.preventDefault()}}
			>{item.name}</a>
		})
	}, [datalist, index]);// : '';
	//useLayer('select');
	//console.log(listitems);
	//console.log(index, value, search, focus)
	return <div><div className="list-group mb-2">
		<div className="input-group2">
			{/* gb220328 weer ongedaan gemaakt focus ? <UseLayer name="modal_select" /> : null*/}
			<input
				ignore_keys='enter'
				style={{ marginBottom: focus ? '-3px' : '' }}
				id={"listitem_a_active_-1"}
				ref={ref}
				autoComplete="off"
				autoCorrect="off"
				//readOnly={isLoading && !updated}
				//autoFocus={data.focus}
				placeholder={data.placeholder ?? 'Choose...'}
				onKeyDown={onKeyDown}
				onBlur={() => { hasFocus(false); }}
				onFocus={() => { hasFocus(true); setLastFocus(data.name); }}
				className={"py-1 px-2 form-control " + (listitems.length ? '' : 'mb-1 ') + (data.cls ?? '')}
				value={value}
				onChange={(evt) => {

					setUpdated(true); setValue(evt.target.value)
				}} />

			<button type="button" className="btn bg-transparent dhide"
				style={{ zIndex: 100, position: 'absolute', right: 16, top: 16, opacity: 0.5 }}
				onClick={() => setValue('')}
			>
				{value ?
					<FaTimesCircle color="#c88" size={24} />
					: ''}
			</button>
			{/*
				<div className="input-group-append">
				<button className="btn btn-secondary" type="button" onClick={() => setValue('')}>X</button>
			</div>
			*/}
		</div>
		{focus ? <div className="focus">{listitems}</div> : ''}
	</div></div>
}
