import React, { useState, useEffect, useContext } from 'react';
import { DispatchContext } from "./Contexts";

export default function ({ toasts, edit }) {
	const toastdivs = toasts.map((toast) => {
		//console.log(toast.mt)
		let data
		try {
			data = JSON.parse(toast.data.replace(/\r/gi,'').replace(/\n/gi,"\\r\\n")) ?? {}
		} catch (e) {
			console.log(e);
			//console.log(toast.data.replace(/\r\n/gi,"\\r\\n"));
			//alert(toast.data);
			data = { secs: 30, cls: "bg-danger text-white", disp: "ERROR IN TOAST: " + toast.data }
		}
		return <Toast key={'t' + toast.mt} data={data} />
	})

	return <div style={{ position: 'absolute', zIndex: 1122, bottom: 0, right: 0, left: edit ? 0 : 'auto', padding: '8px' }}>{toastdivs}</div>
}
function Toast({ data }) {
	const dispatch = useContext(DispatchContext);
	//console.log(data);
	const [visible, setVisible] = useState(true);
	const { secs } = data;
	useEffect(() => {
		let timeout = setTimeout(() => setVisible(false), secs ? secs * 1000 : 5000)
		return ()=>clearTimeout(timeout);

	}, []);
	if (!visible) return null;
	const rightClick = (evt) => {
		dispatch({ type: 'rename', context: 'toast', display: data.disp, name: data.name });
		evt.preventDefault();
	}

	return <div onContextMenu={rightClick} onClick={() => setVisible(false)} className="toast" ><div className={"toast-body " + (data.cls ? data.cls : 'bg-warning')}>{data.disp}</div></div>
}